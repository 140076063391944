.drawer__charts {
    width: 100%;
    padding: 12px;
}

.one {
    width: 33%;
}

.two {
    width: 66%;
}

.three {
    width: 99%;
}

.drawer__charts_empty {
    width: 100%;
    padding: 16px;
    font-weight: 700;
    text-align: center;
}