.drawer__lfm {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
}

.drawer__lfm_teams {
    width: 100%;
    display: flex;
    padding-top: 8px;
    padding-bottom: 8px;
    align-items: center;
    justify-content: space-around;
}

.drawer__lfm_teams .team {
    display: flex;
    align-items: center;
}

.drawer__lfm_teams_image_wrapper {
    width: 30px;
    height: 30px;
    display: flex;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: #eeeeee;
}
  
.drawer__lfm_teams_image {
    width: 24px;
    height: 24px;
    background-size: 100%;
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    /* clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%); */
}

.drawer__lfm_results {
    width: 100%;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.drawer__lfm_results .results {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.drawer__lfm_results .results_left {
    width: 50%;
    margin-right: 12px;
}

.drawer__lfm_results .results_right {
    width: 50%;
    margin-left: 12px;
}

.drawer__lfm_results .result {
    width: 20px;
    height: 20px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 3px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}

.drawer__lfm_results .won {
    background-color: #43a047;
}

.drawer__lfm_results .drew {
    color: black;
    background-color: #ffeb3b;
}

.drawer__lfm_results .lost {
    background-color: #f44336;
}

.drawer__lfm_matches {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.even {
    background-color: #eeeeee;
}

.odd {
    background-color: #e0e0e0;
}

.drawer__lfm_matches .match {
    text-align: center;
}

.drawer__lfm_matches .match .match_left{
    margin-right: 12px;
}

.drawer__lfm_matches .match .match_right {
    margin-left: 12px;
}

.drawer__lfm_matches .match_label {
    width: 100%;
    padding: 4px;
    font-size: 8px;
    text-align: center;
    margin: 0 auto auto;
    text-transform: uppercase;
}

.match_teams {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.match_team {
    font-size: 12px;
}

.match_result {
    width: 48px;
    display: flex;
    font-size: 12px;
    padding: 2px 4px;
    margin: auto 16px;
    align-items: center;
    justify-content: space-between;
    background-color: white;
}

.match_teams_image_wrapper {
    width: 24px;
    height: 24px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: #eeeeee;
}
  
.match_teams_image {
    width: 16px;
    height: 16px;
    background-size: 100%;
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    /* clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%); */
}

.match_indicator {
    display: flex;
    align-items: center;
    justify-content: center;
}

.match_indicator .color {
    width: 4px;
    height: 48px;
    border-radius: 2px;
}

.match__left {
    width: 50%;
    margin-left: 12px;
    margin-right: 8px;
}

.match__right {
    width: 50%;
    margin-left: 8px;
    margin-right: 12px;
}