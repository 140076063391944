@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;500;600;700;900&display=swap");

html {
  box-sizing: border-box;
}

*,
*:before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
  font-family: "Montserrat", sans-serif;
  color: #2b2b2b;
  width: '100%';
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

.validating-auth-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Match {
  max-width: 100%;
  border-radius: 10px;
  border: 1px solid#eeeeee;
  background: #fff;
  margin-top: 10px;
  margin-right: 5px;
}

.Match__Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px 0px 15px;
}

.Match__HeaderCenter__ {
  flex: 0 0 30%;
  max-width: 30%;
}

.Match__HeaderLefttwo {
  flex: 0 0 35%;
  max-width: 35%;
  background-color: #eeeeee;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
}

.Match__HeaderRight_ {
  flex: 0 0 35%;
  max-width: 35%;
}

.Match__HeaderRight_ {
  display: flex;
  justify-content: flex-end;
}

.Match__Header h2 {
  font-weight: 600;
  font-size: 12px;
  text-align: left;
  color: #a7af59;
  text-align: center;
}

.Match__HeaderLeft {
  display: flex;
  align-items: center;
  padding: 2px 0px;
}

.Match__HeaderLeft img {
  max-height: 40px;
}

.Match__Content {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 15px;
}

.Match__Content h2 {
  font-weight: 500;
  font-size: 14px;
}

.Match__Content h5 {
  font-weight: 500;
  font-size: 9px;
  font-style: italic;
  font-weight: bold;
}

.Match__Content img {
  max-height: 15px;
}

.Match__ContentLeft,
.Match__ContentRight {
  display: flex;
  align-items: center;
  text-align: left;
  flex-direction: column;
  flex: 0 0 35%;
  max-width: 35%;
  width: 100%;
}

.Match__ContentRight,
.Match__ContentLeft {
  justify-content: center;
}

.Match__ContentLeft img,
.Match__ContentRight img {
  margin-right: 3px;
  margin: 10px;
}

.Match__ContentCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 0 30%;
  max-width: 30%;
}

.Match__ContentCenter__results {
  text-align: center;
}

.Matchfigures {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Matchbox {
  width: 30px;
  height: 30px;
  background-color: green;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  margin: 4px;
  color: #fff;
  font-size: 18px;
}

.Matchboxtwo {
  width: 50px;
  height: 30px;
  background-color: #fff;
  border: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  margin: 4px;
  color: black;
  font-size: 18px;
}

.Match__ContentCenter__result {
  font-weight: bold;
  font-size: 12px;
}

.Match__ContentCenter__time h5 {
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  font-style: normal;
}

.Match__ContentCenter__date h5 {
  font-weight: 300;
  font-size: 11px;
  text-align: center;
  font-style: normal;
}

.Match__footer {
  display: flex;
  border-top: 1px solid #e5e5e5;
  padding: 5px 15px;
  font-size: 12px;
}

.Match__draw {
  color: #596daf;
  font-weight: bold;
}

.Match__odds {
  display: flex;
  flex: 0 0 30%;
  align-items: center;
}

.Match__oddsWrapper {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Match__points {
  display: flex;
  flex: 0 0 70%;
  justify-content: space-between;
  align-items: center;
}

.Match__points>div {
  flex-shrink: 0;
  flex-grow: 1;
  text-align: left;
}

.Match__point_direction h6 {
  font-weight: 500;
  font-style: italic;
  font-size: 9px;
}

.Match__point_direction svg {
  width: 12px;
  color: #596daf;
  display: inline-block;
  height: 10px;
}

.game_rounds {
  padding: 15px;
  background: white;
  margin-top: 20px;
  width: 100%;
}

.game_rounds .game_rounds__heading {
  text-align: center;
}

.predictionsh1 {
  font-size: 18px;
  text-transform: uppercase;
}

/* .matches {
  display: block;
} */

.groups {
  background: white;
  padding: 15px;
  margin-top: 40px;
  width: 'inherit',
}

/* .groups__container {
  display: flex;
  flex-wrap: wrap;
} */

/* .groups__container > div {
  display: flex;
  flex: 0 0 32%;
} */
.matchPrediction {
  background: white;
  margin-top: 20px;
}

.matchPrediction__round {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: space-around;
}

.matchPrediction__vsIcons {
  display: flex;
  justify-content: space-around;
  max-width: 100vw;
}

.matchPrediction__vsIcons img {
  width: 15%;
}

/* .game_rounds__bestLoser {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  width: 100%;
  justify-content: space-between;
} */

.matchPrediction__save {
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.matchPrediction__save button {
  border: none;
  color: white;
  background: #003;
  padding: 7px 15px;
  border-radius: 30px;
  min-width: 200px;
  margin-top: 15px;
  cursor: pointer;
}

.game_rounds {
  padding: 15px;
  background: white;
  margin-top: 20px;
  width: 100%;
}

.game_rounds .game_rounds__heading {
  text-align: center;
}

.predictionsh1 {
  font-size: 18px;
  text-transform: uppercase;
}

/* .matches {
  display: block;
} */

.groups {
  background: white;
  padding: 15px;
  margin-top: 40px;
  width: 'inherit',
}

/* .groups__container {
  display: flex;
  flex-wrap: wrap;
} */

/* .groups__container > div {
  display: flex;
  flex: 0 0 32%;
} */
.matchPrediction {
  background: white;
  margin-top: 20px;
}

.matchPrediction__round {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: space-around;
}

.matchPrediction__vsIcons {
  display: flex;
  justify-content: space-around;
  max-width: 100vw;
}

.matchPrediction__vsIcons img {
  width: 15%;
}

/* .game_rounds__bestLoser {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  width: 100%;
  justify-content: space-between;
} */

.matchPrediction__save {
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.matchPrediction__save button {
  border: none;
  color: white;
  background: #003;
  padding: 7px 15px;
  border-radius: 30px;
  min-width: 200px;
  margin-top: 15px;
  cursor: pointer;
}

.game_rounds {
  padding: 15px;
  background: white;
  margin-top: 20px;
  width: 100%;
}

.game_rounds .game_rounds__heading {
  text-align: center;
}

.predictionsh1 {
  font-size: 18px;
  text-transform: uppercase;
}

/* .matches {
  display: block;
} */

.groups {
  background: white;
  padding: 15px;
  margin-top: 40px;
  width: 'inherit',
}

/* .groups__container {
  display: flex;
  flex-wrap: wrap;
} */

/* .groups__container > div {
  display: flex;
  flex: 0 0 32%;
} */
.matchPrediction {
  background: white;
  margin-top: 20px;
}

.matchPrediction__round {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: space-around;
}

.matchPrediction__vsIcons {
  display: flex;
  justify-content: space-around;
  max-width: 100vw;
}

.matchPrediction__vsIcons img {
  width: 15%;
}

/* .game_rounds__bestLoser {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  width: 100%;
  justify-content: space-between;
} */

.matchPrediction__save {
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.matchPrediction__save button {
  border: none;
  color: white;
  background: #003;
  padding: 7px 15px;
  border-radius: 30px;
  min-width: 200px;
  margin-top: 15px;
  cursor: pointer;
}