.app {
  /* margin-left: 15px;
  margin-right: 15px; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h1 {
  color: #2b2b2b;
  font-size: 18px;
}
p {
  font-weight: 300;
  font-size: 14px;
}

.input-wrapper {
  margin-right: 0px;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-success {
  color: #fff;
  background-color: #4bb543;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
._loading_overlay_spinner {
  color: red;
  top: 10px;
  height: 100vh !important;
}
._loading_overlay_content {
  display: flex;
  align-items: top;
  flex-direction: column;
  justify-content: top;
}

.img-square {
  background-color: rgba(255, 255, 255, 0.3);
  left: 40px;
  height: 40px;
  width: 40px;
  display: inline-block;
  margin-right: 15px;
  -webkit-box-flex: 1;
  flex: 1;
}
/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .app {
    /* margin-left: 50px;
    margin-right: 50px; */
  }
  h1 {
    font-size: 28px;
    line-height: 30px;
  }
  p {
    font-size: 16px;
  }
  .input-wrapper {
    margin-right: 15px;
  }
  .mobileversion{
      display: none;
  }
}

@media (max-width: 576px) {
  .webversion{
    display: none;
  }
}