.drawer__match_container {
    font-size: 13px;
    margin-top: 12px;
    font-weight: bold;
    display: flex;
    padding-left: 8px;
    padding-right: 8px;
    align-items: center;
    justify-content: space-around;
}

.drawer__match_title {
  width: 100%;
  font-size: 15px;
  font-weight: bold;
  padding-top: 22px;
  text-align: center;
  padding-bottom: 16px;
  background-color: white;
}

.drawer__match_scores {
    width: 48px;
    display: flex;
    border-radius: 3px;
    align-items: center;
    background-color: white;
    justify-content: space-around;
}

.drawer__match_image_wrapper {
    width: 40px;
    height: 40px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: #eeeeee;
}
  
.drawer__match_image {
    width: 32px;
    height: 32px;
    background-size: 100%;
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    /* clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%); */
}

.drawer__match_time {
  padding: 8px;
  font-size: 10px;
  margin-top: -4px;
  text-align: center;
}

.drawer__match_league {
  padding: 8px;
  font-size: 10px;
  text-align: center;
  margin-bottom: -16px;
  text-transform: uppercase;
}