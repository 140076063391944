.drawer__match_progresses {
    width: 100%;
    display: flex;
    padding: 8px 16px;
    align-items: center;
    justify-content: space-between;

    font-size: 12px;
    font-weight: 700;
}

.drawer__match_progresses .progress {
    width: 136px;
}