.drawer__odds_container {
    font-size: 13px;
    font-weight: bold;
    margin-top: 12px;
    display: flex;
    padding-left: 8px;
    padding-right: 8px;
    align-items: center;
    justify-content: space-around;
}

.drawer__odds_vs {
    width: 40px;
    height: 40px;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: #e0e0e0;
}

.drawer__odds_image_wrapper {
    width: 40px;
    height: 40px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: #eeeeee;
}
  
.drawer__odds_image {
    width: 32px;
    height: 32px;
    background-size: 100%;
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    /* clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%); */
}

.drawer_odds_table {
    width: 100%;
    text-align: center;
    border-spacing: 0 6px;
    border-collapse: separate;
}

.drawer_odds_table thead tr {
    background-color: #e0e0e0;
}

.drawer_odds_table thead tr th {
    padding-top: 6px;
    padding-bottom: 6px;
}

.drawer_odds_table tbody tr td {
    width: 20%;
}

.drawer_odds_table tbody tr td button {
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    border-radius: 5px;
    padding: 6px 18px;
    color: white;
    font-family: Montserrat;
    background-color: #31A50C;
    box-shadow: rgba(0, 0, 0, 0.30) 0px 2px 5px;
}