.appLayout__container {
  display: flex;
  flex-direction: row;
}
.appLayout__container .children {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: #f6f6f6;
}
.app {
  flex: 1;
}
