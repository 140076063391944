@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;500;600;700;900&display=swap);
html {
  box-sizing: border-box;
}

*,
*:before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
  font-family: "Montserrat", sans-serif;
  color: #2b2b2b;
  width: '100%';
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

.validating-auth-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Match {
  max-width: 100%;
  border-radius: 10px;
  border: 1px solid#eeeeee;
  background: #fff;
  margin-top: 10px;
  margin-right: 5px;
}

.Match__Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px 0px 15px;
}

.Match__HeaderCenter__ {
  flex: 0 0 30%;
  max-width: 30%;
}

.Match__HeaderLefttwo {
  flex: 0 0 35%;
  max-width: 35%;
  background-color: #eeeeee;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
}

.Match__HeaderRight_ {
  flex: 0 0 35%;
  max-width: 35%;
}

.Match__HeaderRight_ {
  display: flex;
  justify-content: flex-end;
}

.Match__Header h2 {
  font-weight: 600;
  font-size: 12px;
  text-align: left;
  color: #a7af59;
  text-align: center;
}

.Match__HeaderLeft {
  display: flex;
  align-items: center;
  padding: 2px 0px;
}

.Match__HeaderLeft img {
  max-height: 40px;
}

.Match__Content {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 15px;
}

.Match__Content h2 {
  font-weight: 500;
  font-size: 14px;
}

.Match__Content h5 {
  font-weight: 500;
  font-size: 9px;
  font-style: italic;
  font-weight: bold;
}

.Match__Content img {
  max-height: 15px;
}

.Match__ContentLeft,
.Match__ContentRight {
  display: flex;
  align-items: center;
  text-align: left;
  flex-direction: column;
  flex: 0 0 35%;
  max-width: 35%;
  width: 100%;
}

.Match__ContentRight,
.Match__ContentLeft {
  justify-content: center;
}

.Match__ContentLeft img,
.Match__ContentRight img {
  margin-right: 3px;
  margin: 10px;
}

.Match__ContentCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 0 30%;
  max-width: 30%;
}

.Match__ContentCenter__results {
  text-align: center;
}

.Matchfigures {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Matchbox {
  width: 30px;
  height: 30px;
  background-color: green;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  margin: 4px;
  color: #fff;
  font-size: 18px;
}

.Matchboxtwo {
  width: 50px;
  height: 30px;
  background-color: #fff;
  border: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  margin: 4px;
  color: black;
  font-size: 18px;
}

.Match__ContentCenter__result {
  font-weight: bold;
  font-size: 12px;
}

.Match__ContentCenter__time h5 {
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  font-style: normal;
}

.Match__ContentCenter__date h5 {
  font-weight: 300;
  font-size: 11px;
  text-align: center;
  font-style: normal;
}

.Match__footer {
  display: flex;
  border-top: 1px solid #e5e5e5;
  padding: 5px 15px;
  font-size: 12px;
}

.Match__draw {
  color: #596daf;
  font-weight: bold;
}

.Match__odds {
  display: flex;
  flex: 0 0 30%;
  align-items: center;
}

.Match__oddsWrapper {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Match__points {
  display: flex;
  flex: 0 0 70%;
  justify-content: space-between;
  align-items: center;
}

.Match__points>div {
  flex-shrink: 0;
  flex-grow: 1;
  text-align: left;
}

.Match__point_direction h6 {
  font-weight: 500;
  font-style: italic;
  font-size: 9px;
}

.Match__point_direction svg {
  width: 12px;
  color: #596daf;
  display: inline-block;
  height: 10px;
}

.game_rounds {
  padding: 15px;
  background: white;
  margin-top: 20px;
  width: 100%;
}

.game_rounds .game_rounds__heading {
  text-align: center;
}

.predictionsh1 {
  font-size: 18px;
  text-transform: uppercase;
}

/* .matches {
  display: block;
} */

.groups {
  background: white;
  padding: 15px;
  margin-top: 40px;
  width: 'inherit',
}

/* .groups__container {
  display: flex;
  flex-wrap: wrap;
} */

/* .groups__container > div {
  display: flex;
  flex: 0 0 32%;
} */
.matchPrediction {
  background: white;
  margin-top: 20px;
}

.matchPrediction__round {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: space-around;
}

.matchPrediction__vsIcons {
  display: flex;
  justify-content: space-around;
  max-width: 100vw;
}

.matchPrediction__vsIcons img {
  width: 15%;
}

/* .game_rounds__bestLoser {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  width: 100%;
  justify-content: space-between;
} */

.matchPrediction__save {
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.matchPrediction__save button {
  border: none;
  color: white;
  background: #003;
  padding: 7px 15px;
  border-radius: 30px;
  min-width: 200px;
  margin-top: 15px;
  cursor: pointer;
}

.game_rounds {
  padding: 15px;
  background: white;
  margin-top: 20px;
  width: 100%;
}

.game_rounds .game_rounds__heading {
  text-align: center;
}

.predictionsh1 {
  font-size: 18px;
  text-transform: uppercase;
}

/* .matches {
  display: block;
} */

.groups {
  background: white;
  padding: 15px;
  margin-top: 40px;
  width: 'inherit',
}

/* .groups__container {
  display: flex;
  flex-wrap: wrap;
} */

/* .groups__container > div {
  display: flex;
  flex: 0 0 32%;
} */
.matchPrediction {
  background: white;
  margin-top: 20px;
}

.matchPrediction__round {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: space-around;
}

.matchPrediction__vsIcons {
  display: flex;
  justify-content: space-around;
  max-width: 100vw;
}

.matchPrediction__vsIcons img {
  width: 15%;
}

/* .game_rounds__bestLoser {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  width: 100%;
  justify-content: space-between;
} */

.matchPrediction__save {
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.matchPrediction__save button {
  border: none;
  color: white;
  background: #003;
  padding: 7px 15px;
  border-radius: 30px;
  min-width: 200px;
  margin-top: 15px;
  cursor: pointer;
}

.game_rounds {
  padding: 15px;
  background: white;
  margin-top: 20px;
  width: 100%;
}

.game_rounds .game_rounds__heading {
  text-align: center;
}

.predictionsh1 {
  font-size: 18px;
  text-transform: uppercase;
}

/* .matches {
  display: block;
} */

.groups {
  background: white;
  padding: 15px;
  margin-top: 40px;
  width: 'inherit',
}

/* .groups__container {
  display: flex;
  flex-wrap: wrap;
} */

/* .groups__container > div {
  display: flex;
  flex: 0 0 32%;
} */
.matchPrediction {
  background: white;
  margin-top: 20px;
}

.matchPrediction__round {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: space-around;
}

.matchPrediction__vsIcons {
  display: flex;
  justify-content: space-around;
  max-width: 100vw;
}

.matchPrediction__vsIcons img {
  width: 15%;
}

/* .game_rounds__bestLoser {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  width: 100%;
  justify-content: space-between;
} */

.matchPrediction__save {
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.matchPrediction__save button {
  border: none;
  color: white;
  background: #003;
  padding: 7px 15px;
  border-radius: 30px;
  min-width: 200px;
  margin-top: 15px;
  cursor: pointer;
}
.app {
  /* margin-left: 15px;
  margin-right: 15px; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h1 {
  color: #2b2b2b;
  font-size: 18px;
}
p {
  font-weight: 300;
  font-size: 14px;
}

.input-wrapper {
  margin-right: 0px;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-success {
  color: #fff;
  background-color: #4bb543;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
._loading_overlay_spinner {
  color: red;
  top: 10px;
  height: 100vh !important;
}
._loading_overlay_content {
  display: flex;
  align-items: top;
  flex-direction: column;
  justify-content: top;
}

.img-square {
  background-color: rgba(255, 255, 255, 0.3);
  left: 40px;
  height: 40px;
  width: 40px;
  display: inline-block;
  margin-right: 15px;
  flex: 1 1;
}
/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .app {
    /* margin-left: 50px;
    margin-right: 50px; */
  }
  h1 {
    font-size: 28px;
    line-height: 30px;
  }
  p {
    font-size: 16px;
  }
  .input-wrapper {
    margin-right: 15px;
  }
  .mobileversion{
      display: none;
  }
}

@media (max-width: 576px) {
  .webversion{
    display: none;
  }
}
.sidebarMenu {
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  flex-direction: column;
  max-width: 10vw;
}

.MuiDrawer-paperAnchorDockedLeft {
  position: static;
}

.sidebarMenu__item {
  margin: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sidebarMenu__item a {
  text-align: center;
}

.sidebarMenu__item img {
  max-height: 60px;
}

.sidebarMenu__item .logo-text {
  margin-top: -6px;
  margin-left: -7px;
}

.sidebarMenu__item svg {
  display: block;
  font-size: 32px;
  max-width: 50px;
  margin: auto;
  color: #2b2b2b;
  cursor: pointer;
}

.sidebarMenu__item_active svg {
  color: #31A50C;
  fill: #31A50C;
}

.sidebarMenu__item h3 {
  font-size: 12px;
  font-weight: 500;
  font-size: 12px;
  color: #2b2b2b;
  margin-top: 5px;
  text-align: center;
}

.sidebarMenu__item_active h3 {
  color: #31A50C;
  font-weight: 700;
}

@media(max-width: 578px) {
  .sidebarMenu__item {
    margin: 15px 0px;
  }

  .sidebarMenu__item svg {
    font-size: 12px;
    max-width: 25px;
  }

  .sidebarMenu__item h3 {
    font-size: 12px;
  }

}
footer.footer {
  /* margin-top: 3rem; */
  margin-top: 0px;
  position: relative;
  z-index: 60;
  background: #f6f6f6;
  /* padding-top: 25px; */
}
.footer .footerSponsors {
  display: flex;
  flex-grow: 1;
  width: 100%;
  flex-basis: 0;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0x 5px;
}

.footerSponsors img {
  max-height: 60px;
}
.footerBottom {
  background: #2e2e2e;
  color: white;
  padding: 10px 5px;
  /* margin-top: 10px; */
  display: flex;
  flex-direction: row;
}
.footerBottom h5 {
  font-weight: 300;
  font-size: 8px;
}
.footerBottom > div {
  margin-left: auto;
}
.footerBottom__socialIcons svg {
  color: black;
  font-size: 10px;
  min-width: 1em;
}
.footerBottom__socialIcons {
  display: flex;
}

.faCircle {
  color: black;
}

.faCircleContainer {
  border-radius: 50%;
  padding: 1px 6px;
  box-shadow: 0px 0px 4px #888;
  background-color: white;
  margin-right: 5px;
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .footerBottom {
    padding: 16px 30px;
    /* margin-top: 30px; */
  }
  .footer .footerSponsors {
    flex-grow: 1;
    flex-basis: 0;
  }
  .footerBottom h5 {
    font-size: 14px;
  }
  .faCircleContainer {
    padding: 0.25em 0.3em;
    border-radius: 50%;
    box-shadow: 0px 0px 4px #888;
    background-color: white;
    margin-right: 10px;
  }
  .footerBottom__socialIcons svg {
    color: black;
    min-width: 1.2em;
    font-size: 14px;
  }
}

.appLayout__container {
  display: flex;
  flex-direction: row;
}
.appLayout__container .children {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: #f6f6f6;
}
.app {
  flex: 1 1;
}

.drawer__match_container {
    font-size: 13px;
    margin-top: 12px;
    font-weight: bold;
    display: flex;
    padding-left: 8px;
    padding-right: 8px;
    align-items: center;
    justify-content: space-around;
}

.drawer__match_title {
  width: 100%;
  font-size: 15px;
  font-weight: bold;
  padding-top: 22px;
  text-align: center;
  padding-bottom: 16px;
  background-color: white;
}

.drawer__match_scores {
    width: 48px;
    display: flex;
    border-radius: 3px;
    align-items: center;
    background-color: white;
    justify-content: space-around;
}

.drawer__match_image_wrapper {
    width: 40px;
    height: 40px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: #eeeeee;
}
  
.drawer__match_image {
    width: 32px;
    height: 32px;
    background-size: 100%;
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    /* clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%); */
}

.drawer__match_time {
  padding: 8px;
  font-size: 10px;
  margin-top: -4px;
  text-align: center;
}

.drawer__match_league {
  padding: 8px;
  font-size: 10px;
  text-align: center;
  margin-bottom: -16px;
  text-transform: uppercase;
}
.drawer__odds_container {
    font-size: 13px;
    font-weight: bold;
    margin-top: 12px;
    display: flex;
    padding-left: 8px;
    padding-right: 8px;
    align-items: center;
    justify-content: space-around;
}

.drawer__odds_vs {
    width: 40px;
    height: 40px;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: #e0e0e0;
}

.drawer__odds_image_wrapper {
    width: 40px;
    height: 40px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: #eeeeee;
}
  
.drawer__odds_image {
    width: 32px;
    height: 32px;
    background-size: 100%;
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    /* clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%); */
}

.drawer_odds_table {
    width: 100%;
    text-align: center;
    border-spacing: 0 6px;
    border-collapse: separate;
}

.drawer_odds_table thead tr {
    background-color: #e0e0e0;
}

.drawer_odds_table thead tr th {
    padding-top: 6px;
    padding-bottom: 6px;
}

.drawer_odds_table tbody tr td {
    width: 20%;
}

.drawer_odds_table tbody tr td button {
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    border-radius: 5px;
    padding: 6px 18px;
    color: white;
    font-family: Montserrat;
    background-color: #31A50C;
    box-shadow: rgba(0, 0, 0, 0.30) 0px 2px 5px;
}
.drawer__hth {
    width: 100%;
    display: flex;
    padding: 12px 16px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
}
.drawer__standings_team_name {
    display: flex;
    align-items: center;
}

.drawer__standings_image_wrapper {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    border-radius: 50%;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    background-color: #eeeeee;
}
  
.drawer__standings_image {
    width: 24px;
    height: 24px;
    background-size: 100%;
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    /* clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%); */
}

.drawer_standings_table {
    width: 100%;
    margin-top: -8px;
    text-align: center;
    border-spacing: 0 4px;
    border-collapse: separate;
}

.drawer_standings_table thead tr {
    background-color: #e0e0e0;
}

.drawer_standings_table thead tr th:first-child {
    text-align: left;
    padding-left: 8px;
}

.drawer_standings_table thead tr th {
    font-size: 13px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.drawer_standings_table tbody tr td {
    font-size: 12px;
    text-align: center;
}

.drawer_standings_table tbody tr td:first-child {
    text-align: left;
    padding-left: 8px;
}

.drawer__standings_see_all {
    padding: 8px;
    z-index: 100;
    text-align: center;
    margin-bottom: -18px;
}

.drawer__standings_see_all button {
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    padding: 6px 18px;
    border-radius: 32px;
    color:black;
    font-family: Montserrat;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.30) 0px 2px 5px;
}
.drawer__lfm {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
}

.drawer__lfm_teams {
    width: 100%;
    display: flex;
    padding-top: 8px;
    padding-bottom: 8px;
    align-items: center;
    justify-content: space-around;
}

.drawer__lfm_teams .team {
    display: flex;
    align-items: center;
}

.drawer__lfm_teams_image_wrapper {
    width: 30px;
    height: 30px;
    display: flex;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: #eeeeee;
}
  
.drawer__lfm_teams_image {
    width: 24px;
    height: 24px;
    background-size: 100%;
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    /* clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%); */
}

.drawer__lfm_results {
    width: 100%;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.drawer__lfm_results .results {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.drawer__lfm_results .results_left {
    width: 50%;
    margin-right: 12px;
}

.drawer__lfm_results .results_right {
    width: 50%;
    margin-left: 12px;
}

.drawer__lfm_results .result {
    width: 20px;
    height: 20px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 3px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}

.drawer__lfm_results .won {
    background-color: #43a047;
}

.drawer__lfm_results .drew {
    color: black;
    background-color: #ffeb3b;
}

.drawer__lfm_results .lost {
    background-color: #f44336;
}

.drawer__lfm_matches {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.even {
    background-color: #eeeeee;
}

.odd {
    background-color: #e0e0e0;
}

.drawer__lfm_matches .match {
    text-align: center;
}

.drawer__lfm_matches .match .match_left{
    margin-right: 12px;
}

.drawer__lfm_matches .match .match_right {
    margin-left: 12px;
}

.drawer__lfm_matches .match_label {
    width: 100%;
    padding: 4px;
    font-size: 8px;
    text-align: center;
    margin: 0 auto auto;
    text-transform: uppercase;
}

.match_teams {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.match_team {
    font-size: 12px;
}

.match_result {
    width: 48px;
    display: flex;
    font-size: 12px;
    padding: 2px 4px;
    margin: auto 16px;
    align-items: center;
    justify-content: space-between;
    background-color: white;
}

.match_teams_image_wrapper {
    width: 24px;
    height: 24px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: #eeeeee;
}
  
.match_teams_image {
    width: 16px;
    height: 16px;
    background-size: 100%;
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    /* clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%); */
}

.match_indicator {
    display: flex;
    align-items: center;
    justify-content: center;
}

.match_indicator .color {
    width: 4px;
    height: 48px;
    border-radius: 2px;
}

.match__left {
    width: 50%;
    margin-left: 12px;
    margin-right: 8px;
}

.match__right {
    width: 50%;
    margin-left: 8px;
    margin-right: 12px;
}
.drawer__match_progresses {
    width: 100%;
    display: flex;
    padding: 8px 16px;
    align-items: center;
    justify-content: space-between;

    font-size: 12px;
    font-weight: 700;
}

.drawer__match_progresses .progress {
    width: 136px;
}
.drawer__charts {
    width: 100%;
    padding: 12px;
}

.one {
    width: 33%;
}

.two {
    width: 66%;
}

.three {
    width: 99%;
}

.drawer__charts_empty {
    width: 100%;
    padding: 16px;
    font-weight: 700;
    text-align: center;
}
.root {
    width: 600px;
}

.drawer__close {
    top: 0px;
    left: 0px;
    width: 100%;
    position: fixed;
}

.drawer__close button {
    cursor: pointer;
}

.results__drawer_progress {
    width: 100%;
    height: 100vh;
    text-align: center;
    padding-top: 160px;
}

.results__drawer_progress .progress {
    color: #31A50C;
}
