.root {
    width: 600px;
}

.drawer__close {
    top: 0px;
    left: 0px;
    width: 100%;
    position: fixed;
}

.drawer__close button {
    cursor: pointer;
}

.results__drawer_progress {
    width: 100%;
    height: 100vh;
    text-align: center;
    padding-top: 160px;
}

.results__drawer_progress .progress {
    color: #31A50C;
}