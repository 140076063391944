.sidebarMenu {
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  flex-direction: column;
  max-width: 10vw;
}

.MuiDrawer-paperAnchorDockedLeft {
  position: static;
}

.sidebarMenu__item {
  margin: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sidebarMenu__item a {
  text-align: center;
}

.sidebarMenu__item img {
  max-height: 60px;
}

.sidebarMenu__item .logo-text {
  margin-top: -6px;
  margin-left: -7px;
}

.sidebarMenu__item svg {
  display: block;
  font-size: 32px;
  max-width: 50px;
  margin: auto;
  color: #2b2b2b;
  cursor: pointer;
}

.sidebarMenu__item_active svg {
  color: #31A50C;
  fill: #31A50C;
}

.sidebarMenu__item h3 {
  font-size: 12px;
  font-weight: 500;
  font-size: 12px;
  color: #2b2b2b;
  margin-top: 5px;
  text-align: center;
}

.sidebarMenu__item_active h3 {
  color: #31A50C;
  font-weight: 700;
}

@media(max-width: 578px) {
  .sidebarMenu__item {
    margin: 15px 0px;
  }

  .sidebarMenu__item svg {
    font-size: 12px;
    max-width: 25px;
  }

  .sidebarMenu__item h3 {
    font-size: 12px;
  }

}