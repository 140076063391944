footer.footer {
  /* margin-top: 3rem; */
  margin-top: 0px;
  position: relative;
  z-index: 60;
  background: #f6f6f6;
  /* padding-top: 25px; */
}
.footer .footerSponsors {
  display: flex;
  flex-grow: 1;
  width: 100%;
  flex-basis: 0;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0x 5px;
}

.footerSponsors img {
  max-height: 60px;
}
.footerBottom {
  background: #2e2e2e;
  color: white;
  padding: 10px 5px;
  /* margin-top: 10px; */
  display: flex;
  flex-direction: row;
}
.footerBottom h5 {
  font-weight: 300;
  font-size: 8px;
}
.footerBottom > div {
  margin-left: auto;
}
.footerBottom__socialIcons svg {
  color: black;
  font-size: 10px;
  min-width: 1em;
}
.footerBottom__socialIcons {
  display: flex;
}

.faCircle {
  color: black;
}

.faCircleContainer {
  border-radius: 50%;
  padding: 1px 6px;
  box-shadow: 0px 0px 4px #888;
  background-color: white;
  margin-right: 5px;
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .footerBottom {
    padding: 16px 30px;
    /* margin-top: 30px; */
  }
  .footer .footerSponsors {
    flex-grow: 1;
    flex-basis: 0;
  }
  .footerBottom h5 {
    font-size: 14px;
  }
  .faCircleContainer {
    padding: 0.25em 0.3em;
    border-radius: 50%;
    box-shadow: 0px 0px 4px #888;
    background-color: white;
    margin-right: 10px;
  }
  .footerBottom__socialIcons svg {
    color: black;
    min-width: 1.2em;
    font-size: 14px;
  }
}
