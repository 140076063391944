.drawer__standings_team_name {
    display: flex;
    align-items: center;
}

.drawer__standings_image_wrapper {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    border-radius: 50%;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    background-color: #eeeeee;
}
  
.drawer__standings_image {
    width: 24px;
    height: 24px;
    background-size: 100%;
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    /* clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%); */
}

.drawer_standings_table {
    width: 100%;
    margin-top: -8px;
    text-align: center;
    border-spacing: 0 4px;
    border-collapse: separate;
}

.drawer_standings_table thead tr {
    background-color: #e0e0e0;
}

.drawer_standings_table thead tr th:first-child {
    text-align: left;
    padding-left: 8px;
}

.drawer_standings_table thead tr th {
    font-size: 13px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.drawer_standings_table tbody tr td {
    font-size: 12px;
    text-align: center;
}

.drawer_standings_table tbody tr td:first-child {
    text-align: left;
    padding-left: 8px;
}

.drawer__standings_see_all {
    padding: 8px;
    z-index: 100;
    text-align: center;
    margin-bottom: -18px;
}

.drawer__standings_see_all button {
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    padding: 6px 18px;
    border-radius: 32px;
    color:black;
    font-family: Montserrat;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.30) 0px 2px 5px;
}